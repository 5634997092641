<template>
  <div>
    <a id="top"></a>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Model
          <small class="primary--text">{{ selectedModel.overrideName ? selectedModel.overrideName : selectedModel.name }}</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Series
          <small class="primary--text">{{ selectedSeries.name }}</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3">
          Acrylic
          <small class="primary--text">{{  }}</small>
        </v-stepper-step>

        <v-divider></v-divider>

         <v-stepper-step :complete="e1 > 4" step="4">
          Cabinet
          <small class="primary--text">{{  }}</small>
        </v-stepper-step>

        <v-divider></v-divider>

         <v-stepper-step :complete="e1 > 5" step="5">
           Cover
           <small class="primary--text">{{ }}</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 6" step="6">
          Features/Options
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 7" step="7">
          Add-Ons
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 8" step="8">
          Order Summary
        </v-stepper-step>


        <div class="pa-2 d-flex align-center">
          <v-chip
            class="ma-2"
            color="primary"
            text-color="white"
          >
          ${{ totalCurrentPrice }}
          </v-chip>
        </div>

      </v-stepper-header>

      <v-card elevation="0">
        <v-stepper-items>
          <!-- Step 1: Model -->
          <v-stepper-content step="1" class="pt-0">
         
            <v-item-group v-model="order.modelId" @change="changeModel()">
              <v-container v-for="group in modelGroup" :key="group.id">

                <!-- Large Spas -->
                
                <v-row  justify="center" v-if="currentModelGroup(group) && currentModelGroup(group).length>0 && group.name==='8\''">
                  
                  <v-card color="grey lighten-2" width="100%" elevation="0" class="d-flex align-center">
                    <v-card-text class="text-center">
                      <h1 class="primary--text">{{ group.name }}</h1><h2 class="secondary--text"> Spas</h2>
                    </v-card-text>
                  </v-card>
                  </v-row>

                  <v-row  justify="center" v-if="currentModelGroup(group) && currentModelGroup(group).length>0 && group.name==='8\''">
                  <v-col
                    v-for="model in currentModelGroup(group)"
                    :key="model.id"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                      <v-item
                        v-slot="{ active, toggle }"
                        :value="model.modelId"
                      >
                        <v-card class="d-flex align-center rounded-lg" :height="getCardHeight(model.group)" :width="getCardWidth(model.group)" elevation="0" @click="toggle">
                          <v-img
                            :src="model.image"
                            contain
                            height="100%"
                            :aspect-ratio="1/1"
                          >
                            <div
                              v-if="active"
                              class="d-flex justify-center align-center"
                            >
                              <v-overlay
                                absolute
                                :value="toggle"
                                :opacity="0.45"
                              >
                                <v-container class="d-flex justify-center">
                                  <h3 class="d-flex align-center pr-2">
                                    Currently Selected
                                  </h3>
                                  <v-icon large color="accent">
                                    mdi-check-circle
                                  </v-icon>
                                </v-container>
                              </v-overlay>
                            </div>
                          </v-img>
                        </v-card>
                      </v-item>
                      <v-card-title class="d-flex justify-center primary--text pa-0">
                        <v-container class="text-center pa-0">
                        {{ model.overrideName ? model.overrideName : model.name }}
                        </v-container>
                      </v-card-title>
                      <v-card-subtitle class="d-flex justify-center secondary--text">
                        <v-container class="text-center">
                        {{ model.description }}
                        </v-container>
                      </v-card-subtitle>
                  </v-col>
                  
                </v-row>
                <!-- Medium Spas -->
                <v-row  justify="center" v-if="currentModelGroup(group) && currentModelGroup(group).length>0 && group.name==='7\''">
                  
                  <v-card color="grey lighten-2" width="100%" elevation="0" class="d-flex align-center">
                    <v-card-text class="text-center">
                      <h1 class="primary--text">{{ group.name }}</h1><h2 class="secondary--text"> Spas</h2>
                    </v-card-text>
                  </v-card>
                  </v-row>

                  <v-row  justify="center" v-if="currentModelGroup(group) && currentModelGroup(group).length>0 && group.name==='7\''">
                  <v-col
                    v-for="model in currentModelGroup(group)"
                    :key="model.id"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                  >
                      <v-item
                        v-slot="{ active, toggle }"
                        :value="model.modelId"
                      >
                        <v-card class="d-flex align-center rounded-lg" :height="getCardHeight(model.group)" :width="getCardWidth(model.group)" elevation="0" @click="toggle">
                          <v-img
                            :src="model.image"
                            contain
                            height="100%"
                            :aspect-ratio="1/1"
                          >
                            <div
                              v-if="active"
                              class="d-flex justify-center align-center"
                            >
                              <v-overlay
                                absolute
                                :value="toggle"
                                :opacity="0.45"
                              >
                                <v-container class="d-flex justify-center">
                                  <h3 class="d-flex align-center pr-2">
                                    Currently Selected
                                  </h3>
                                  <v-icon large color="accent">
                                    mdi-check-circle
                                  </v-icon>
                                </v-container>
                              </v-overlay>
                            </div>
                          </v-img>
                        </v-card>
                      </v-item>
                      <v-card-title class="d-flex justify-center primary--text pa-0">
                        <v-container class="text-center pa-0">
                        {{ model.overrideName ? model.overrideName : model.name }}
                        </v-container>
                      </v-card-title>
                      <v-card-subtitle class="d-flex justify-center secondary--text">
                        <v-container class="text-center">
                        {{ model.description }}
                        </v-container>
                      </v-card-subtitle>
                  </v-col>
                </v-row>

                <!-- Small Spas -->
                <v-row  justify="center" v-if="currentModelGroup(group) && currentModelGroup(group).length>0 && group.name==='Small'">
                  
                  <v-card color="grey lighten-2" width="100%" elevation="0" class="d-flex align-center">
                    <v-card-text class="text-center">
                      <h1 class="primary--text">{{ group.name }}</h1><h2 class="secondary--text"> Spas</h2>
                    </v-card-text>
                  </v-card>
                  </v-row>

                  <v-row  justify="center" v-if="currentModelGroup(group) && currentModelGroup(group).length>0 && group.name==='Small'">
                  <v-col
                    v-for="model in currentModelGroup(group)"
                    :key="model.id"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="3"
                    lg="3"
                  >
                      <v-item
                        v-slot="{ active, toggle }"
                        :value="model.modelId"
                      >
                        <v-card class="d-flex align-center rounded-lg" :height="getCardHeight(model.group)" :width="getCardWidth(model.group)" elevation="0" @click="toggle">
                          <v-img
                            :src="model.image"
                            contain
                            height="100%"
                            :aspect-ratio="1/1"
                          >
                            <div
                              v-if="active"
                              class="d-flex justify-center align-center"
                            >
                              <v-overlay
                                absolute
                                :value="toggle"
                                :opacity="0.45"
                              >
                                <v-container class="d-flex justify-center">
                                  <h3 class="d-flex align-center pr-2">
                                    Currently Selected
                                  </h3>
                                  <v-icon large color="accent">
                                    mdi-check-circle
                                  </v-icon>
                                </v-container>
                              </v-overlay>
                            </div>
                          </v-img>
                        </v-card>
                      </v-item>
                      <v-card-title class="d-flex justify-center primary--text pa-0">
                        <v-container class="text-center pa-0">
                        {{ model.overrideName ? model.overrideName : model.name }}
                        </v-container>
                      </v-card-title>
                      <v-card-subtitle class="d-flex justify-center secondary--text">
                        <v-container class="text-center">
                        {{ model.description }}
                        </v-container>
                      </v-card-subtitle>
                  </v-col>
                  
                </v-row>

                <!-- Swim Spa -->

                <v-row  justify="center" v-if="currentModelGroup(group) && currentModelGroup(group).length>0 && group.name==='S.W.I.M.'">
                  <v-card color="grey lighten-2" width="100%" elevation="0" class="d-flex align-center">
                    <v-card-text class="text-center">
                      <h1 class="primary--text">{{ group.name }}</h1><h2 class="secondary--text"> Spas</h2>
                    </v-card-text>
                  </v-card>
                </v-row>
                <v-row  justify="center" v-if="currentModelGroup(group) && currentModelGroup(group).length>0 && group.name==='S.W.I.M.'">
                  <v-col
                    v-for="model in currentModelGroup(group)"
                    :key="model.id"
                    cols="12"
                    xs="12"
                    sm="9"
                    md="6"
                    lg="6"
                  >
                      <v-item
                        v-slot="{ active, toggle }"
                        :value="model.modelId"
                      >
                        <v-card class="align-center rounded-lg" height="300" elevation="0" @click="toggle">
                          <v-img
                            :src="model.image"
                            contain
                            height="100%"
                          >
                            <div
                              v-if="active"
                              class="d-flex justify-center align-center"
                            >
                              <v-overlay
                                absolute
                                :value="toggle"
                                :opacity="0.45"
                              >
                                <v-container class="d-flex justify-center">
                                  <h3 class="d-flex align-center pr-2">
                                    Currently Selected
                                  </h3>
                                  <v-icon large color="accent">
                                    mdi-check-circle
                                  </v-icon>
                                </v-container>
                              </v-overlay>
                            </div>
                          </v-img>
                        </v-card>
                      </v-item>
                      <v-card-title class="d-flex justify-center primary--text pa-0">
                        <v-container class="text-center pa-0">
                        {{ model.overrideName ? model.overrideName : model.name }}
                        </v-container>
                      </v-card-title>
                      <v-card-subtitle class="d-flex justify-center secondary--text">
                        <v-container class="text-center">
                        {{ model.description }}
                        </v-container>
                      </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
            <v-container class="d-flex justify-end">
              <v-btn v-if="this.order.modelId" color="primary" dark @click="e1 = 2">
                Continue
                <v-icon dark right> mdi-arrow-right </v-icon>
              </v-btn>
            </v-container>
          </v-stepper-content>

          <!-- Step 2: Series -->

          <v-stepper-content step="2">
            <v-item-group v-model="order.seriesId" @change="changeSeries()">
              <div class="d-flex justify-center align-center"><v-btn outlined color="primary" @click="compare = true">
                <v-icon color="primary" left>mdi-information</v-icon>  
                <div class="secondary--text font-weight-bold">Compare Series</div>
                <v-icon color="primary" right>mdi-information</v-icon>
                </v-btn></div>
              <v-container>
               
                <v-row justify="center">
                  <v-col
                    v-for="s in currentSeries"
                    :key="s.seriesId"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="10"
                  >
                    <v-container>
                      <v-item v-slot="{ active, toggle }" :value="s.seriesId">
                        <v-card @click="toggle" class="rounded-lg">
                          <v-card-title class="secondary--text">{{ s.name }}</v-card-title>

                          <v-card-actions>
                            <span class="secondary--text font-weight-bold">
                              {{ s.description }}
                            </span>
                            <v-spacer></v-spacer>
                            <span>${{  Number(s.price) }}</span>
                          </v-card-actions>

                          <div
                            v-if="active"
                            class="d-flex justify-center align-center"
                          >
                            <v-overlay absolute :value="toggle" :opacity="0.45">
                              <v-container class="d-flex justify-center">
                                <h3 class="d-flex align-center pr-2">
                                  Currently Selected
                                </h3>
                                <v-icon large color="accent">
                                  mdi-check-circle
                                </v-icon>
                              </v-container>
                            </v-overlay>
                          </div>
                        </v-card>
                      </v-item>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>

                      <v-dialog
                      v-model="compare"
                      transition="dialog-bottom-transition">
                      <v-card> <v-btn
                      color="red"
                      elevation="1"
                      outlined
                      block
                      @click="compare = false"
                      >Close</v-btn></v-card>
                     
                    
                     <v-container fluid>
                    
                      <v-row justify="center">
                        <v-col
                          v-for="s in currentSeries"
                          :key="s.seriesId"
                          cols="12"
                          lg="4"
                          md="6"
                          sm="12">
                      <v-card v-if="selectedModel.group === 'S.W.I.M.'"
                      outlined
                      height="700">
                        <v-card-title>
                          {{ selectedModel.name }} {{ s.name }}
                        </v-card-title>
                       
                        <v-card-subtitle>
                          ${{  Number(s.price) }}
                        </v-card-subtitle>
                        <v-card-text>
                         <span class="font-weight-bold">{{ s.total_pump }}</span> Total Pumps <br/>
                         <span class="font-weight-bold">{{ s.therapy_pump }}</span> XP2E Gecko <span class="text-decoration-underline">Therapy</span> Pump <br/>
                         <span class="font-weight-bold">{{ s.total_pump-s.therapy_pump }}</span> XP3E Gecko <span class="text-decoration-underline">SWIM</span> Pumps <br/><br/>
                         <span v-if="s.pump1"><span class="font-weight-bold">Pump 1:</span> {{ s.pump1 }}<br/></span>
                         <span v-if="s.pump2"><span class="font-weight-bold">Pump 2:</span> {{ s.pump2 }}<br/></span> 
                         <span v-if="s.pump3"><span class="font-weight-bold">Pump 3:</span> {{ s.pump3 }}<br/></span>
                         <span v-if="s.pump4"><span class="font-weight-bold">Pump 4:</span> {{ s.pump4 }}<br/></span>
                         <span v-if="s.circPump"><span class="font-weight-bold">Circ Pump:</span> {{ s.circPump }}<br/></span><br/>  
                         <span class="font-weight-bold">Jets:</span>
                         {{ s.hydroJet }} Spectrum Stainless / Halo Jets <br/>
                         <span class="font-weight-bold">Swim Jets:</span>
                         {{ s.volJet }} <br/><br/>
                         <span class="font-weight-bold">Top Side Control:</span> Gecko {{ s.topSide }} <br/>
                         <span class="font-weight-bold">Spa Pack:</span> Gecko {{ s.pack }} <br/>
                         <span class="font-weight-bold">Mobile Control: </span>{{ s.inTouch }} <br/> <br/>
                         <span class="font-weight-bold">{{ s.ledLighting }}</span> <br/>
                         <span v-if="s.Led1">1. {{ s.Led1 }}<br/></span> 
                         <span v-if="s.Led2">2. {{ s.Led2 }}<br/></span> 
                         <span v-if="s.Led3">3. {{ s.Led3 }}<br/></span> <br/>
                         <span v-if="s.waterfall"><span class="font-weight-bold">Waterfall Feature:</span> {{ s.waterfall }} <br/> </span> <span class="font-weight-bold">Filter System:</span> {{ s.filter }}<br/> <br/>
                         <span class="font-weight-bold">Warranty:</span> {{ s.warranty }}
                        </v-card-text>
                  
                      </v-card>
                      
                      <v-card v-else
                      outlined
                      height="600">
                        <v-card-title>
                          {{ selectedModel.name }} {{ s.name }}
                        </v-card-title>
                       
                        <v-card-subtitle>
                          ${{  Number(s.price) }}
                        </v-card-subtitle>
                        <v-card-text>
                         <span class="font-weight-bold">{{ s.total_pump }}</span> Total Pumps --- <span class="font-weight-bold">{{ s.therapy_pump }}</span>XP2E Gecko Therapy Pumps<br/>
                         <span class="font-weight-bold">Pump 1:</span>  {{ s.pump1 }} <br/>
                         <span v-if="s.pump2"><span class="font-weight-bold">Pump 2:</span> {{ s.pump2 }}<br/></span> 
                         <span v-if="s.pump3"><span class="font-weight-bold">Pump 3:</span> {{ s.pump3 }}<br/></span> 
                         <span class="font-weight-bold"> Circ Pump: </span>{{ s.circPump }} <br/> <br/>
                         <span class="font-weight-bold">Jets:</span>
                         {{ s.hydroJet }} Spectrum Stainless / Halo Jets <br/>
                         <span class="font-weight-bold">Vol Jets:</span>
                         {{ s.volJet }} <br/> <br/>
                         <span class="font-weight-bold">Top Side Control:</span> Gecko {{ s.topSide }} <br/> <span class="font-weight-bold">Spa Pack:</span> Gecko {{ s.pack }} <br/>
                         <span class="font-weight-bold">Mobile Control: </span>{{ s.inTouch }} <br/> <br/>
                         <span class="font-weight-bold">{{ s.ledLighting }}</span><br/> 
                         <span v-if="s.Led1">1. {{ s.Led1 }} <br/> </span> 
                         <span v-if="s.Led2">2. {{ s.Led2 }} <br/> </span> 
                         <span v-if="s.Led3">3. {{ s.Led3 }} <br/> </span> <br/>
                         <span v-if="(s.seriesId === 15 || s.seriesId === 16)"> <span class="font-weight-bold">Holiday Highlights:</span> <br/>
                        1. Lockable Top Side Control <br/> 2. Tamper Proof Air Controls <br/> 3. Quick Drain System <br/> 4. Anti Air Lock Bleeder Valve <br/> 5. NO Waterfall Features <br/> <br/> </span>
                         <span v-if="s.waterfall"><span class="font-weight-bold">Waterfall Feature:</span> {{ s.waterfall }} <br/> </span> <span class="font-weight-bold">Filter System:</span> {{ s.filter }}<br/> <br/>
                         <span class="font-weight-bold">Warranty:</span> {{ s.warranty }} <br/>
                         
                        </v-card-text>
                  
                      </v-card>  
                      </v-col>
                      </v-row>
                     </v-container>
                    </v-dialog>

            </v-item-group>
            <v-container class="d-flex justify-space-between">
              <v-btn color="secondary" @click="e1 = 1">
                <v-icon left> mdi-arrow-left </v-icon>Back
              </v-btn>

              <v-btn v-if="this.order.seriesId" color="primary" dark @click="e1 = 3">
                Continue
                <v-icon dark right> mdi-arrow-right </v-icon>
              </v-btn>
            </v-container>
          </v-stepper-content>

          <!-- Step 3: Acrylic Color Options -->
          <v-stepper-content step="3">
              <v-item-group
                mandatory
                v-for="optionType in currentColorOptions"
                :key="optionType.catalogOptionTypeId"
                v-model="order.options[getOptionsIndex(optionType.catalogOptionTypeId)].optionId"
                fluid
                color="primary"
              >
                <v-row><v-card height=8></v-card></v-row>
                <!-- <v-subheader dark class="primary--text text-h6 justify-center"> {{ optionType.name }} </v-subheader> -->
                <v-row justify="center" v-if="optionType.name==='Acrylic Color'">
                <v-card color="grey lighten-2" width="100%" elevation="0" class="d-flex">
                   <v-card-text class="text-center">
                      <h1 class="primary--text">Acrylic</h1>
                      <h2 class="secondary--text"> Colors</h2>
                    </v-card-text> 
                  </v-card>
                </v-row>

                <v-row class='d-flex justify-center' v-if="optionType.name==='Acrylic Color'">
                  <v-col
                    v-for="option in optionType.options"
                    :key="option.optionId"
                    :value="option.optionId"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="4"
                    lg="4"
                  >
                 
                    <v-container>
                      <v-item
                        v-slot="{ active, toggle }"
                        :value="option.optionId"
                      >

                        <v-card class="secondary--text text-center rounded"
                          @click="toggle"
                        >
                            <v-img
                              :src="option.image"
                              contain
                            ></v-img>
                            <div
                              v-if="active"
                              class="d-flex justify-center align-center"
                            >
                              <v-overlay
                                absolute
                                :value="toggle"
                                :opacity="0.15"
                              >
                                <v-container class="d-flex justify-center">
                                  <h3 class="d-flex align-center pr-2">
                                    Currently Selected
                                  </h3>
                                  <v-icon large color="accent">
                                    mdi-check-circle
                                  </v-icon>
                                </v-container>
                              </v-overlay>
                            </div>
                            <v-card-title>
                            <span class="text-truncate">{{ option.name }}</span><v-spacer></v-spacer><span class="caption font-weight-light" v-if="option.price && option.price > 0">Add ${{ option.price }}</span>
                            </v-card-title>
                          </v-card>
                        </v-item>
                      </v-container>
                    
                    </v-col>
                  </v-row>
              </v-item-group>                

            <v-container class="d-flex justify-space-between">
              <v-btn color="secondary" @click="e1 = 2">
                <v-icon left> mdi-arrow-left </v-icon>Back
              </v-btn>

              <v-btn color="primary" dark @click="e1 = 4">
                Continue
                <v-icon dark right> mdi-arrow-right </v-icon>
              </v-btn>
            </v-container>
          </v-stepper-content>

           <!-- Step 4: Cabinet Color Options -->
          <v-stepper-content step="4">
              <v-item-group
                mandatory
                v-for="optionType in currentColorOptions"
                :key="optionType.catalogOptionTypeId"
                v-model="order.options[getOptionsIndex(optionType.catalogOptionTypeId)].optionId"
                fluid
                color="primary"
              >
                <v-row><v-card height=8></v-card></v-row>
                <!-- <v-subheader dark class="primary--text text-h6 justify-center"> {{ optionType.name }} </v-subheader> -->
                <v-row justify="center" v-if="optionType.name==='Cabinet Color'">
                <v-card color="grey lighten-2" width="100%" elevation="0" class="d-flex">
                   <v-card-text class="text-center">
                      <h1 class="primary--text">Cabinet</h1>
                      <h2 class="secondary--text"> Colors</h2>
                    </v-card-text> 
                  </v-card>
                </v-row>

                

                  <v-row class='d-flex justify-center' v-if="optionType.name==='Cabinet Color'">
                  <v-col
                    v-for="option in optionType.options"
                    :key="option.optionId"
                    :value="option.optionId"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="4"
                    lg="4"
                  >
                    <v-container>
                      <v-item
                        v-slot="{ active, toggle }"
                        :value="option.optionId"
                      >

                        <v-card  class="secondary--text text-center rounded-lg"
                          @click="toggle"
                        >
                            <v-img
                              :src="option.image"
                              contain
                            ></v-img>
                            <div
                              v-if="active"
                              class="d-flex justify-center align-center"
                            >
                              <v-overlay
                                absolute
                                :value="toggle"
                                :opacity="0.15"
                              >
                                <v-container class="d-flex justify-center">
                                  <h3 class="d-flex align-center pr-2">
                                    Currently Selected
                                  </h3>
                                  <v-icon large color="accent">
                                    mdi-check-circle
                                  </v-icon>
                                </v-container>
                              </v-overlay>
                            </div>
                            <v-card-title>
                            <span class="text-truncate">{{ option.name }}</span><v-spacer></v-spacer><span class="caption font-weight-light" v-if="option.price && option.price > 0">Add ${{ option.price }}</span>
                            </v-card-title>
                          </v-card>
                        </v-item>
                      </v-container>
                    </v-col>
                  </v-row>

                  


              </v-item-group>                

            <v-container class="d-flex justify-space-between">
              <v-btn color="secondary" @click="e1 = 3">
                <v-icon left> mdi-arrow-left </v-icon>Back
              </v-btn>

              <v-btn color="primary" dark @click="e1 = 5">
                Continue
                <v-icon dark right> mdi-arrow-right </v-icon>
              </v-btn>
            </v-container>
          </v-stepper-content>

           <!-- Step 5: Cover Color Options -->
          <v-stepper-content step="5">
              <v-item-group
                mandatory
                v-for="optionType in currentColorOptions"
                :key="optionType.catalogOptionTypeId"
                v-model="order.options[getOptionsIndex(optionType.catalogOptionTypeId)].optionId"
                fluid
                color="primary"
              >
                <v-row><v-card height=8></v-card></v-row>
                <!-- <v-subheader dark class="primary--text text-h6 justify-center"> {{ optionType.name }} </v-subheader> -->
                <v-row justify="center" v-if="optionType.name==='Cover Color'">
                <v-card color="grey lighten-2" width="100%" elevation="0" class="d-flex">
                   <v-card-text class="text-center">
                      <h1 class="primary--text">Cover</h1>
                      <h2 class="secondary--text"> Colors</h2>
                    </v-card-text> 
                  </v-card>
                </v-row>

                
                  <v-row class='d-flex justify-center' v-if="optionType.name==='Cover Color'">
                  <v-col
                    v-for="option in optionType.options"
                    :key="option.optionId"
                    :value="option.optionId"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <v-container>
                      <v-item
                        v-slot="{ active, toggle }"
                        :value="option.optionId"
                      >

                        <v-card class="secondary--text text-center rounded-lg"
                          @click="toggle"
                        >
                            <v-img
                              :src="option.image"
                              contain
                            ></v-img>
                            <div
                              v-if="active"
                              class="d-flex justify-center align-center"
                            >
                              <v-overlay
                                absolute
                                :value="toggle"
                                :opacity="0.15"
                              >
                                <v-container class="d-flex justify-center">
                                  <h3 class="d-flex align-center pr-2">
                                    Currently Selected
                                  </h3>
                                  <v-icon large color="accent">
                                    mdi-check-circle
                                  </v-icon>
                                </v-container>
                              </v-overlay>
                            </div>
                            <v-card-title>
                            <span class="text-h6">{{ option.name }}</span><v-spacer></v-spacer><span class="caption font-weight-light" v-if="option.price && option.price > 0">Add ${{ option.price }}</span>
                            </v-card-title>
                          </v-card>
                        </v-item>
                      </v-container>
                    </v-col>
                  </v-row>


              </v-item-group>                

            <v-container class="d-flex justify-space-between">
              <v-btn color="secondary" @click="e1 = 4">
                <v-icon left> mdi-arrow-left </v-icon>Back
              </v-btn>

              <v-btn color="primary" dark @click="e1 = 6">
                Continue
                <v-icon dark right> mdi-arrow-right </v-icon>
              </v-btn>
            </v-container>
          </v-stepper-content>

          <!-- Step 6: Other Options -->
          <v-stepper-content step="6">
            <v-container class="d-flex justify-space-around">
              <v-row>
                <v-col
                  v-for="optionType in currentOtherOptions"
                  :key="optionType.catalogOptionTypeId"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <v-card class="rounded-lg">
                    <v-list>
                      <v-card-title class="primary--text subheading font-weight-bold"> {{ optionType.name }} </v-card-title>
                      <v-divider></v-divider>

                      <v-list-item-group
                        mandatory
                        class="text-h4"
                        v-model="order.options[getOptionsIndex(optionType.catalogOptionTypeId)].optionId"
                        color="primary">
                   
                        <v-list-item
                          v-for="option in optionType.options"
                          :key="option.optionid"
                          :value="option.optionId">
                       
                        <v-tooltip v-if="option.description" color="#000000" right>
                        <template v-slot:activator="{ on }">
                          <v-icon color="secondary" left v-on="on">mdi-information-box</v-icon>
                        </template>
                        <span>{{ option.description }}</span>
                       </v-tooltip>

                       <v-tooltip v-if="option.image" color="#000000" right>
                        <template v-slot:activator="{ on }">
                          <v-icon color="secondary" left v-on="on">mdi-panorama-variant</v-icon>
                        </template>
                        <span>
                          <v-container>
                            <v-card class="rounded-lg">
                              <v-img
                            :src="option.image"
                            width="300"
                            cover
                            >
                            </v-img>

                            </v-card>
                            </v-container>
                            
                          
                        </span>
                       </v-tooltip>

                          <v-list-item-content>
                           <v-list-item-subtitle class="secondary--text font-weight-bold">{{option.name}}</v-list-item-subtitle>
                          </v-list-item-content>
                      
                          <v-list-item-action-text>
                            <span class="caption font-weight-light" v-if="option.price && option.price > 0">Add ${{ option.price }}</span>
                            <span v-else-if="option.price && option.price<0">Subtract ${{Math.abs(option.price)}}</span>
                            <span v-else-if="option.standard"><v-icon v-if="option.standard" color="primary" right>mdi-leaf</v-icon></span>
                          </v-list-item-action-text>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="d-flex justify-space-between">
              <v-btn color="secondary" @click="e1 = 5">
                <v-icon left> mdi-arrow-left </v-icon>Back
              </v-btn>

              <v-btn color="primary" dark @click="e1 = 7">
                Continue
                <v-icon dark right> mdi-arrow-right </v-icon>
              </v-btn>
            </v-container>
          </v-stepper-content>

          <!-- Step 7: Add Ons -->
          <v-stepper-content step="7">
            <v-container class="d-flex justify-space-around">
              <v-row>
                <v-col
                  v-for="optionType in currentAddOns"
                  :key="optionType.catalogOptionTypeId"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <v-card class="rounded-lg">
                    <v-list>
                      <v-card-title class="primary--text subheading font-weight-bold"> {{ optionType.name }} </v-card-title>
                      <v-divider></v-divider>

                      <v-list-item-group
                        mandatory
                        class="text-h4"
                        v-model="order.options[getOptionsIndex(optionType.catalogOptionTypeId)].optionId"
                        color="primary">

                        <v-list-item
                          v-for="option in optionType.options"
                          :key="option.optionid"
                          :value="option.optionId">

                          <v-tooltip v-if="option.description" color="#000000" right>
                        <template v-slot:activator="{ on }">
                          <v-icon color="secondary" left v-on="on">mdi-information-box</v-icon>
                        </template>
                        <span>{{ option.description }}</span>
                       </v-tooltip>

                       <v-tooltip v-if="option.image" color="#000000" right>
                        <template v-slot:activator="{ on }">
                          <v-icon color="secondary" left v-on="on">mdi-panorama-variant</v-icon>
                        </template>
                        <span>
                          <v-container>
                            <v-card class="rounded-lg">
                              <v-img
                            :src="option.image"
                            width="300"
                            cover
                            >
                            </v-img>

                            </v-card>
                            </v-container>
                            
                          
                        </span>
                       </v-tooltip>

                          <v-list-item-content>
                            <v-list-item-subtitle class="secondary--text font-weight-bold">{{option.name}} </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action-text>
                            <span class="caption font-weight-light" v-if="option.price && option.price > 0">Add ${{ option.price }}</span>
                            <span v-else-if="option.price && option.price<0">Subtract ${{Math.abs(option.price)}}</span>
                            <span v-else-if="option.standard"><v-icon v-if="option.standard" color="primary" right>mdi-leaf</v-icon></span>
                          </v-list-item-action-text>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="d-flex justify-space-between">
              <v-btn color="secondary" @click="e1 = 6">
                <v-icon left> mdi-arrow-left </v-icon>Back
              </v-btn>

              <v-btn color="primary" dark @click="e1 = 8">
                Continue
                <v-icon dark right> mdi-arrow-right </v-icon>
              </v-btn>
            </v-container>
          </v-stepper-content>


          <!-- Step 8: Order Summary -->
          <v-stepper-content step="8">
            <v-container>
       
                <v-row>
                <v-col>
                      <v-alert
                      :value="success"
                      outlined
                      type="success"
                      text
                      transition="scale-transition">
                      <v-row>
                        <v-col class="text-center grow">
                          Thank you! You have successfully submitted this order. <br/> You can update this order until it is scheduled for production.
                        </v-col>
                      </v-row> 
                      <v-row>
                         <v-btn :to="'/order/new/'+order.id">Place Another Order</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn v-if="currentUser.userTypeId !== 1" :to="'/order-list'">View Orders</v-btn>
                          <v-btn v-if="currentUser.userTypeId === 1" :to="'/order-list-aspen-emp'">View Orders</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn :to="'/order/'+order.id+'/true'">Copy This Order</v-btn>
                      </v-row>
                    </v-alert>
                </v-col>
              </v-row>

              <v-container v-if="this.order.status === 'Retail Draft'" class="d-flex justify-center">
                <v-row>
          
                <v-col cols="6">
                  <v-card max-width="500">
                    <v-card-title class="primary--text subheading font-weight-bold justify-center">Retail Order Summary</v-card-title>
                    <v-card-text>
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-content class="secondary--text">
                              <span class="text-h6 text-left">{{ selectedModel.overrideName ? selectedModel.overrideName : selectedModel.name }}  {{ selectedSeries.name }}</span>
                            </v-list-item-content>
                            <v-list-item-action-text>
                              ${{ Number(this.order.retailSeriesPrice) }}
                            </v-list-item-action-text>
                          </v-list-item>
                          <v-list-item
                              v-for="option in sortColorOptions"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.retailPrice }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>
                          <v-list-item v-if="sortUpOptions.length>0" class="font-italic">Upgrades:</v-list-item>
                          <v-list-item v-else class="font-italic">No Upgrades</v-list-item>
                          <v-list-item
                              v-for="option in sortUpOptions"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.retailPrice }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>

                           <v-list-item v-if="sortAddOns.length>0" class="font-italic">Add-Ons:</v-list-item>
                          <v-list-item v-else class="font-italic">No Add-Ons</v-list-item>
                          <v-list-item
                              v-for="option in sortAddOns"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.retailPrice }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>

                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content>Retail Total</v-list-item-content>
                            <v-list-item-action-text><span class="font-weight-bold">${{ this.order.retailCost }}</span></v-list-item-action-text>
                          </v-list-item>
                        </v-list>
                        <v-textarea
                          class="px-4"
                          v-model="order.specialInstructions"
                          auto-grow
                          rows="1"
                          :label="order.specialInstructions ? 'Special Instructions': null"
                          placeholder="Enter Special Instructions..."
                          persistent-placeholder
                        ></v-textarea>

                        <v-text-field
                          ref="customer"
                          class="px-4"
                          v-model="order.customer"
                          :label="order.customer ? 'Customer Name' : null"
                          placeholder="Enter Customer Name..."
                          persistent-placeholder
                          :rules="[() => !!order.customer || 'Customer is required. We recommend using stock if not yet sold.']"
                        ></v-text-field>

                        <v-text-field
                          ref="poNumber"
                          class="px-4"
                          v-model="order.poNumber"
                          :label="order.poNumber ? 'PO Number' : null"
                          placeholder="Enter PO Number..."
                          persistent-placeholder
                        ></v-text-field>


                        <v-text-field v-if="currentUser.userTypeId === 1"                          
                          class="px-4"
                          type="number" 
                          label="Serial Number" 
                          v-model="order.serialNumber"
                          hint="Leave blank to get next available number"
                          persistent-hint
                        ></v-text-field>

                         <v-select v-if="currentUser.userTypeId === 1 && this.order.status !== 'Retail Draft'"                          
                          class="px-4"
                    placeholder="Select Dealer..."
                    v-model="order.dealerId"
                    :items="dealers"
                    item-text="dealerName"
                    item-value="dealerId"
                    :rules="[rules.required]"
                        ></v-select>

                    </v-card-text>
                  </v-card>
                </v-col>

                
                <v-col cols="6">
                  <v-card max-width="500">
                    <v-card-title class="primary--text subheading font-weight-bold justify-center">Wholesale Order Summary</v-card-title>
                    <v-card-text>
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-content class="secondary--text">
                              <span class="text-h6 text-left">{{ selectedModel.overrideName ? selectedModel.overrideName : selectedModel.name }}  {{ selectedSeries.name }}</span>
                            </v-list-item-content>
                            <v-list-item-action-text>
                              ${{ Number(selectedModel.price) + Number(selectedSeries.price) }}
                            </v-list-item-action-text>
                          </v-list-item>
                          <v-list-item
                              v-for="option in sortColorOptions"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.price }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>
                          <v-list-item v-if="sortUpOptions.length>0" class="font-italic">Upgrades:</v-list-item>
                          <v-list-item v-else class="font-italic">No Upgrades</v-list-item>
                          <v-list-item
                              v-for="option in sortUpOptions"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.price }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>

                           <v-list-item v-if="sortAddOns.length>0" class="font-italic">Add-Ons:</v-list-item>
                          <v-list-item v-else class="font-italic">No Add-Ons</v-list-item>
                          <v-list-item
                              v-for="option in sortAddOns"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.price }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>

                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content>Wholesale Total</v-list-item-content>
                            <v-list-item-action-text><span class="font-weight-bold">${{ totalCurrentPrice }}</span></v-list-item-action-text>
                          </v-list-item>
                        </v-list>
                        <v-textarea
                          class="px-4"
                          v-model="order.specialInstructions"
                          auto-grow
                          rows="1"
                          :label="order.specialInstructions ? 'Special Instructions': null"
                          placeholder="Enter Special Instructions..."
                          persistent-placeholder
                        ></v-textarea>

                        <v-text-field
                          ref="customer"
                          class="px-4"
                          v-model="order.customer"
                          :label="order.customer ? 'Customer Name' : null"
                          placeholder="Enter Customer Name..."
                          persistent-placeholder
                          :rules="[() => !!order.customer || 'Customer is required. We recommend using stock if not yet sold.']"
                        ></v-text-field>

                        <v-text-field
                          ref="poNumber"
                          class="px-4"
                          v-model="order.poNumber"
                          :label="order.poNumber ? 'PO Number' : null"
                          placeholder="Enter PO Number..."
                          persistent-placeholder
                        ></v-text-field>


                        <v-text-field v-if="currentUser.userTypeId === 1"                          
                          class="px-4"
                          type="number" 
                          label="Serial Number" 
                          v-model="order.serialNumber"
                          hint="Leave blank to get next available number"
                          persistent-hint
                        ></v-text-field>

                         <v-select v-if="currentUser.userTypeId === 1 && this.order.status !== 'Retail Draft'"                          
                          class="px-4"
                    placeholder="Select Dealer..."
                    v-model="order.dealerId"
                    :items="dealers"
                    item-text="dealerName"
                    item-value="dealerId"
                    :rules="[rules.required]"
                        ></v-select>

                    </v-card-text>
                  </v-card>
                </v-col>

                <v-overlay :value="saving">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
                </v-row> </v-container>

            <v-container v-else>  <v-row>
                <v-col cols="12" offset="3">
                  <v-card max-width="500">
                    <v-card-title class="primary--text subheading font-weight-bold justify-center">Order Summary</v-card-title>
                    <v-card-text>
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-content class="secondary--text">
                              <span class="text-h6 text-left">{{ selectedModel.overrideName ? selectedModel.overrideName : selectedModel.name }}  {{ selectedSeries.name }}</span>
                            </v-list-item-content>
                            <v-list-item-action-text>
                              ${{ Number(selectedModel.price) + Number(selectedSeries.price) }}
                            </v-list-item-action-text>
                          </v-list-item>
                          <v-list-item
                              v-for="option in sortColorOptions"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.price }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>
                          <v-list-item v-if="sortUpOptions.length>0" class="font-italic">Upgrades:</v-list-item>
                          <v-list-item v-else class="font-italic">No Upgrades</v-list-item>
                          <v-list-item
                              v-for="option in sortUpOptions"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.price }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>

                           <v-list-item v-if="sortAddOns.length>0" class="font-italic">Add-Ons:</v-list-item>
                          <v-list-item v-else class="font-italic">No Add-Ons</v-list-item>
                          <v-list-item
                              v-for="option in sortAddOns"
                              :key="option.optionTypeId"
                          >
                          <v-list-item-content class="pa-0">
                            <div class="text-left">{{ option.optionTypeName }}: <span class="subtitle-1">{{ option.name }}</span></div>
                          </v-list-item-content>
                            <v-list-item-action-text>
                              <span v-if="option.price">${{ option.price }}</span><span v-else>Included</span>
                            </v-list-item-action-text>
                          </v-list-item>

                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content>Total</v-list-item-content>
                            <v-list-item-action-text><span class="font-weight-bold">${{ totalCurrentPrice }}</span></v-list-item-action-text>
                          </v-list-item>
                        </v-list>
                        <v-textarea
                          class="px-4"
                          v-model="order.specialInstructions"
                          auto-grow
                          rows="1"
                          :label="order.specialInstructions ? 'Special Instructions': null"
                          placeholder="Enter Special Instructions..."
                          persistent-placeholder
                        ></v-textarea>

                        <v-text-field
                          ref="customer"
                          class="px-4"
                          v-model="order.customer"
                          :label="order.customer ? 'Customer Name' : null"
                          placeholder="Enter Customer Name..."
                          persistent-placeholder
                          :rules="[() => !!order.customer || 'Customer is required. We recommend using stock if not yet sold.']"
                        ></v-text-field>

                        <v-text-field
                          ref="poNumber"
                          class="px-4"
                          v-model="order.poNumber"
                          :label="order.poNumber ? 'PO Number' : null"
                          placeholder="Enter PO Number..."
                          persistent-placeholder
                        ></v-text-field>


                        <v-text-field v-if="currentUser.userTypeId === 1"                          
                          class="px-4"
                          type="number" 
                          label="Serial Number" 
                          v-model="order.serialNumber"
                          hint="Leave blank to get next available number"
                          persistent-hint
                        ></v-text-field>

                         <v-select v-if="currentUser.userTypeId === 1 && this.order.status !== 'Retail Draft'"                          
                          class="px-4"
                    placeholder="Select Dealer..."
                    v-model="order.dealerId"
                    :items="dealers"
                    item-text="dealerName"
                    item-value="dealerId"
                    :rules="[rules.required]"
                        ></v-select>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

                <v-overlay :value="saving">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay> </v-container>

            </v-container>

             <v-container v-if="this.prevDealerId === 126 && this.order.dealerId !== 126 && currentUser.userTypeId !== 1" class="d-flex justify-center">
             
              <v-dialog 
              v-model="dialog"
              width="600"
              transition="dialog-bottom-transition">
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn v-if="currentUser.userTypeId !== 4"
                       color="primary"
                        v-bind="attrs"
                        v-on="on"
                       >
                       {{ 'Claim Order' }}
                       </v-btn>
                     </template>
              
              <v-card>
                <v-btn
                  color="red"
                  elevation="1"
                  icon
                  outlined
                  x-small 
                  @click="dialog = false"
                >X</v-btn>
                
                <v-list>
                  <v-list-item><span class="text-center font-weight-bold">By placing this order you agree to the Payment and Pricing Terms and Conditions outlined below.</span></v-list-item>
                  <br/>
                  <v-list-item>
                    <span class="text-center mx-auto"><span class="font-weight-bold">Payment Terms:</span><br/>
                    CIA Wire Transfer / Check: Received Prior to Shipment.<br/>
                    Make Checks Payable to Aspen Spas<br/>
                    Credit Card: 3% Processing Fee (AmEx 4%)<br/>
                    Blue tarp: 30 days @ 2.97% 60 Days @ 4.22% 90 Days @ 4.82%<br/>
                    </span>
                  </v-list-item>
                  <br/>
                  <v-list-item>
                    <span class="text-center"><span class="font-weight-bold">Pricing Terms:</span><br/>
                     Pricing Effective October 2022.  Aspen Spas reverse the right to change pricing with proper notification to all dealers.
                      Manufacture Surcharges no longer apply.<br/>
                      
                      </span>
                  </v-list-item>
                  <br/>
                  <v-list-item>
                   <span class="text-center mx-auto"> Pricing shown on portal reflect final cost of spa. <br/>
                      Freight cost quoted at time of delivery.</span>
                  </v-list-item>
                  <br/>
                  <v-list-item>
                    <span class="text-center">
                      Please Contact Your Sales Representitive for Questions or Call (314) 776-5050<br/>
                      4609 Mcree Ave Saint Louis, Mo 63110
                    </span>
                  </v-list-item>
                </v-list>

                <v-btn
                color="primary"
                @click="saveOrder(); dialog = false;"
                block>
                {{ 'Claim Order' }}
                </v-btn>
            </v-card>
            </v-dialog>
            </v-container>

            <v-container v-else-if="this.order.status === 'Retail Draft'" class="d-flex justify-center">
             
             <v-dialog 
             v-model="dialog"
             width="600"
             transition="dialog-bottom-transition">
                 <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="currentUser.userTypeId !== 4"
                      color="primary"
                       v-bind="attrs"
                       v-on="on"
                      >
                      {{ 'Submit Order' }}
                      </v-btn>
                    </template>
             
             <v-card>
               <v-btn
                 color="red"
                 elevation="1"
                 icon
                 outlined
                 x-small 
                 @click="dialog = false"
               >X</v-btn>
               
               <v-list>
                 <v-list-item><span class="text-center font-weight-bold">By placing this order you agree to the Payment and Pricing Terms and Conditions outlined below.</span></v-list-item>
                 <br/>
                 <v-list-item>
                   <span class="text-center mx-auto"><span class="font-weight-bold">Payment Terms:</span><br/>
                   CIA Wire Transfer / Check: Received Prior to Shipment.<br/>
                   Make Checks Payable to Aspen Spas<br/>
                   Credit Card: 3% Processing Fee (AmEx 4%)<br/>
                   Blue tarp: 30 days @ 2.97% 60 Days @ 4.22% 90 Days @ 4.82%<br/>
                   </span>
                 </v-list-item>
                 <br/>
                 <v-list-item>
                   <span class="text-center"><span class="font-weight-bold">Pricing Terms:</span><br/>
                    Pricing Effective October 2022.  Aspen Spas reverse the right to change pricing with proper notification to all dealers.
                     Manufacture Surcharges no longer apply.<br/>
                     
                     </span>
                 </v-list-item>
                 <br/>
                 <v-list-item>
                  <span class="text-center mx-auto"> Pricing shown on portal reflect final cost of spa. <br/>
                     Freight cost quoted at time of delivery.</span>
                 </v-list-item>
                 <br/>
                 <v-list-item>
                   <span class="text-center">
                     Please Contact Your Sales Representitive for Questions or Call (314) 776-5050<br/>
                     4609 Mcree Ave Saint Louis, Mo 63110
                   </span>
                 </v-list-item>
               </v-list>

               <v-btn
               color="primary"
               @click="saveOrder(); dialog = false;"
               block>
               {{ 'Submit Order' }}
               </v-btn>
           </v-card>
           </v-dialog>
           </v-container>

            <v-container v-else class="d-flex justify-space-between justify-center">
            
              <v-btn color="secondary" @click="e1 = 7">
                <v-icon left> mdi-arrow-left </v-icon>Back
              </v-btn>
              <v-dialog 
              v-model="dialog"
              width="600"
              transition="dialog-bottom-transition">
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn v-if="currentUser.userTypeId !== 4"
                       color="primary"
                        v-bind="attrs"
                        v-on="on"
                       >
                       {{ order.id ? 'Update Order' : 'Place Order' }}
                       </v-btn>
                     </template>
              
              <v-card>
                <v-btn
                  color="red"
                  elevation="1"
                  icon
                  outlined
                  x-small 
                  @click="dialog = false"
                >X</v-btn>
                
                <v-list>
                  <v-list-item><span class="text-center font-weight-bold">By placing this order you agree to the Payment and Pricing Terms and Conditions outlined below.</span></v-list-item>
                  <br/>
                  <v-list-item>
                    <span class="text-center mx-auto"><span class="font-weight-bold">Payment Terms:</span><br/>
                    CIA Wire Transfer / Check: Received Prior to Shipment.<br/>
                    Make Checks Payable to Aspen Spas<br/>
                    Credit Card: 3% Processing Fee (AmEx 4%)<br/>
                    Blue tarp: 30 days @ 2.97% 60 Days @ 4.22% 90 Days @ 4.82%<br/>
                    </span>
                  </v-list-item>
                  <br/>
                  <v-list-item>
                    <span class="text-center"><span class="font-weight-bold">Pricing Terms:</span><br/>
                     Pricing Effective October 2022.  Aspen Spas reverse the right to change pricing with proper notification to all dealers.
                      Manufacture Surcharges no longer apply.<br/>
                      
                      </span>
                  </v-list-item>
                  <br/>
                  <v-list-item>
                   <span class="text-center mx-auto"> Pricing shown on portal reflect final cost of spa. <br/>
                      Freight cost quoted at time of delivery.</span>
                  </v-list-item>
                  <br/>
                  <v-list-item>
                    <span class="text-center">
                      Please Contact Your Sales Representitive for Questions or Call (314) 776-5050<br/>
                      4609 Mcree Ave Saint Louis, Mo 63110
                    </span>
                  </v-list-item>
                </v-list>
                
                <v-btn
                color="primary"
                @click="saveOrder(); dialog = false;"
                block>
                {{ order.id ? 'Update Order' : 'Place Order' }}
                </v-btn>
            </v-card>
            </v-dialog>
            </v-container>

          </v-stepper-content>
        </v-stepper-items>
      </v-card>
    </v-stepper>
        <v-dialog
          width="350"
          v-model="dealerDialog"
          persistent
        >
          <v-card>
            <v-card-title>Dealer for this Order</v-card-title>
                <v-select                      
                    class="px-4"
                    placeholder="Select Dealer..."
                    v-model="order.dealerId"
                    :items="dealers"
                    item-text="dealerName"
                    item-value="dealerId"
                    :rules="[rules.required]"
                ></v-select>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="setDealer()">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>
import { getCatalog, getCatalogByDealer, createOrder, editOrder, getOrder, getDealersForUser, getRetailCatalogOrder } from "@/services";

export default {
  name: "Order",

  data: () => ({
    catalog: {},
    rcatalog: {},
    order: { id: null, userId: null },
    e1: 1,
    saving: false,
    success: false,
    currentGroup: null,
    dialog: false,
    compare: false,
    timeout: -1,
    dealers: [],
    prevDealerId: null,
    dealerDialog: false,
    modelGroup: [
      { id: 1, name: "8'" },
      { id: 2, name: "7'" },
      { id: 3, name: 'Small' },
      { id: 4, name: "S.W.I.M." },
    ],
    rules: { required: value => !!value || 'Required' }
  }),

  mounted() {
    if (this.orderId) {
      //get the order
      getOrder(this.orderId).then((result) => {
        this.order = result.data;
        this.prevDealerId = this.order.dealerId;
        if (this.copy) {
          this.order.id = null;
          this.order.customer = null;
          this.order.userId = this.currentUser.id;
          this.order.serialNumber = null;
        }

      if ((this.currentUser.dealers && this.currentUser.dealers.length > 1) || this.currentUser.userTypeId == 1) {
        getDealersForUser().then((result) => {
          this.dealers = result.data;
        });

      } else {
        this.order.dealerId = this.currentUser.dealers[0];
      }
      
      if (this.order.status === 'Retail Draft') {
        var dealerId = this.order.dealerId;
        getRetailCatalogOrder(dealerId).then((catResult) => {
          this.catalog = catResult.data;
          //add any missing optionTypes
          this.catalog.models.forEach(m => {
            m.series.forEach(s => {
              s.optionTypes.forEach(ot => {
                if (this.getOptionsIndex(ot.catalogOptionTypeId) === -1) {
                  this.order.options.push({ catalogOptionTypeId: ot.catalogOptionTypeId, optionTypeName: ot.name, group: ot.group, optionId: null });
                }
              });
            });
          });
        });
      } else {
        var catalogId = this.order.catalogId;
        getCatalog(catalogId).then((catResult) => {
          this.catalog = catResult.data;
          //add any missing optionTypes
          this.catalog.models.forEach(m => {
            m.series.forEach(s => {
              s.optionTypes.forEach(ot => {
                if (this.getOptionsIndex(ot.catalogOptionTypeId) === -1) {
                  this.order.options.push({ catalogOptionTypeId: ot.catalogOptionTypeId, optionTypeName: ot.name, group: ot.group, optionId: null });
                }
              });
            });
          });
        });
      }
        
      });
      this.e1=8;
    }
    else {
      //new order
      this.order.id = null;
      this.order.userId = this.currentUser.id;

      if ((this.currentUser.dealers && this.currentUser.dealers.length > 1) || this.currentUser.userTypeId == 1) {
        //dealer selection dialog
        getDealersForUser().then((result) => {
          this.dealers = result.data;
          this.dealerDialog = true;
        });

      } else {
        this.order.dealerId = this.currentUser.dealers[0];
        this.initCatalog();
      }
    }
  },

  props: ['orderId', 'copy'], 

  computed: {
    currentUser() { return this.$store.state.user },
    currentSeries: function () {
      if (this.selectedModel.modelId) {
        return this.selectedModel.series;
      }
      return null;
    },
    currentColorOptions: function () {
      if (this.selectedSeries.seriesId) {
        return this.selectedSeries.optionTypes.filter(
          (a) =>
            a.group === "color"
        );
      }
      return null;
    },
    currentOtherOptions: function () {
      if (this.selectedSeries.seriesId) {
        return this.selectedSeries.optionTypes.filter(
          (a) =>
            a.group === "other"
        );
      }
      return null;
    },
     currentAddOns: function () {
      if (this.selectedSeries.seriesId) {
        return this.selectedSeries.optionTypes.filter(
          (a) =>
            a.group === "add-ons"
        );
      }
      return null;
    },
    selectedModel: function () {
      if (this.order.modelId && this.catalog.models) {
        return this.catalog.models.find(
          (r) => this.order.modelId === r.modelId
        );
      }
      return { name: "No Model Selected", price: 0 };
    },
    selectedSeries: function () {
      if (this.order.seriesId && this.selectedModel.modelId) {
        return this.selectedModel.series.find(
          (q) => this.order.seriesId === q.seriesId
        );
      }
      return { name: "No Series Selected", price: 0 };
    },
    selectedOptions: function () {
      if (this.order.options && this.selectedSeries.seriesId) {
      var types = this.order.options.filter(o => o.optionId);
      var options = types.map(t => {
        var option = this.getOption(t.optionId);
        if (option) {
          option.optionTypeName = t.optionTypeName;
          option.group = t.group;
          option.optionTypeSequence = t.optionTypeSequence
        }
        return option;
      });
      return options.filter(o => o != undefined);
      }
      return [];
    },

    selectedColorOptions: function () {
      return this.selectedOptions.filter(o => o.group === 'color');
    },
    sortColorOptions: function() {
      let sortedOptions = this.selectedColorOptions;
      sortedOptions.sort((a, b) => 
         a.optionTypeSequence - b.optionTypeSequence);
          return sortedOptions;
    },

    selectedOtherUpdgradedOptions: function () {
      return this.selectedOptions.filter(o => o.group === 'other' && o.standard === null);
    },
   
    sortUpOptions: function() {
      let sortedOptions = this.selectedOtherUpdgradedOptions;
      sortedOptions.sort((a, b) => 
         a.optionTypeSequence - b.optionTypeSequence);
          return sortedOptions;
    },

     selectedAddOns: function () {
      return this.selectedOptions.filter(o => o.group === 'add-ons' && o.standard === null);
    },
    sortAddOns: function() {
      let sortedOptions = this.selectedAddOns;
      sortedOptions.sort((a, b) => 
         a.optionTypeSequence - b.optionTypeSequence);
          return sortedOptions;
    },

    totalCurrentPrice: function () {
      var total = 0;
      if (this.selectedSeries.price) {
        total +=
          Number(this.selectedSeries.price);
      }

      if (this.selectedOptions) {
        this.selectedOptions.forEach((o) => {
          if (o.price) {
            total += Number(o.price);
          }
        });
      }
      return total;
    },

  },

  methods: {
    initCatalog() {
        getCatalogByDealer(this.order.dealerId).then((result) => {
          this.catalog = result.data;
          this.order.catalogId = this.catalog.catalogId;

          this.$set(this.order, 'options', []);
          this.$set(this.order, 'seriesId', null);

        });
       
    },
    setDealer() {
      if (this.order.dealerId) {
        this.initCatalog(); 
        this.dealerDialog=false;
      }
    },
    getOption(optionId) {
      if (this.selectedSeries.optionTypes) {
      return this.selectedSeries.optionTypes.flatMap(ot => ot.options).find(o => o.optionId === optionId);
      }
      return {};
    },
    getOptionsIndex(catalogTypeId) {
      return this.order.options.findIndex((o) => o.catalogOptionTypeId === catalogTypeId);
    },
    getDefaultOptionId(catalogTypeId) {
      let defOption = this.selectedSeries.optionTypes.find(ot => ot.catalogOptionTypeId === catalogTypeId).options.find((o) => o.standard === 1);
      return defOption ? defOption.optionId : null;
    },
    saveOrder() {
      //validate customer
      if (!this.order.customer) {
        this.$refs["customer"].validate(true);
        console.log("hello")
        return;
      }
      this.saving = true;
      this.order.totalCost = this.totalCurrentPrice;
      if (this.order.id > 0) {
      editOrder(this.order).then(result => {
        this.order.serialNumber = result.data.serialNumber;
        this.saving = false;
        this.success = true;
        window.location.href = "#top";
      });
      } else {
      createOrder(this.order).then(result => {
        this.order.id = result.data.id;
        this.order.serialNumber = result.data.serialNumber;
        this.saving = false;
        this.success = true;
        window.location.href = "#top";
        });
      }
    },
    currentModelGroup(group) {
      if(this.catalog.catalogId) {
        return this.catalog.models.filter((model) => model.group === group.name);
      }
      return null;   
    },

    changeModel() {
      this.order.seriesId = null;
      this.removeOrderOptions();
    },

    changeSeries() {
      this.removeOrderOptions();
      this.populateOrderOptions();
    },

    removeOrderOptions() {
      this.order.options = [];
    },

    populateOrderOptions() {
      if (this.selectedSeries) {
        this.selectedSeries.optionTypes.forEach(ot => {
          this.order.options.push({ catalogOptionTypeId: ot.catalogOptionTypeId, optionTypeName: ot.name, group: ot.group, optionId: this.getDefaultOptionId(ot.catalogOptionTypeId) });
        });
      }
    },

    getCardHeight(group) {
      if(group === "Small") {
        return 350;
      }
      else {
        return 300;
      }
    },
    
    getCardWidth(group) {
      if(group === "S.W.I.M.") {
        return 600;
      }
       else {
        return null
      }
    },
  },
};
</script>

<style></style>
