<template>
  <v-sheet elevation="0" class="">

    <div v-if="userTypeId === 1" class="d-print-none"> 
      <v-card elevation="2" class="mb-4 mt-2 rounded-lg">
      <v-row>
        <v-col cols="4">
          <v-card-title class="py-0 text-h3 primary--text font-weight-bold">{{ order.dealerName }}</v-card-title>
          <v-card-text class="py-0 my-0"> Order Date: <span class="font-weight-bold">{{ moment.utc(order.createdAt).local().format('MM/DD/YYYY') }}</span></v-card-text>
          <v-card-text class="py-0 my-0">Order Number: <span class="font-weight-bold">{{ this.orderId }}</span></v-card-text>
          <v-card-text class=" py-0 my-0"> Order Placed By: <span class="font-weight-bold"> {{ order.usersFirstName + " " + order.usersLastName }} </span></v-card-text>
        </v-col>
        <v-col cols="3">
          <v-row justify="center">
            <v-container>
              <div class="text-h6 pt-0 font-weight-bold text-center"> Dealer Address </div>
              <div class="body-1 primary--text font-weight-bold text-center"> {{ order.dealerName }} </div>
              <div class="subitle-2 text-center"> {{ order.dealerAddress1 }} </div>
              <div class="subitle-2 text-center"> {{ order.dealerCity + " " + order.dealerStateProvince + ", " + order.dealerPostalCode + " " +order.dealerCountry }} </div>
            </v-container></v-row>
          </v-col><v-col cols="3"><v-row justify="center">
            <v-container>
              <div class="pt-0 text-h6 font-weight-bold text-center"> Contact Info </div>
              <div class="subitle-2 text-center"> {{ order.dealerPhone }} </div>
              <div class="subitle-2 text-center"> {{ order.dealerEmail }} </div>
            </v-container>
          </v-row>
        </v-col>
        <v-col cols="2"><v-row justify="center">
            <v-container>
          <div class="pt-0 text-center text-h6 font-weight-bold"> Status </div>
          <div class="text-center">
            <v-chip color="primary"> {{ order.status }} </v-chip>
          </div>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-card> </div>

    <v-divider></v-divider>

    <v-card class="rounded-lg my-0" elevation="2">
      <!-- <v-row>
        <v-col> -->
          <v-card-title class="text-h4 primary--text font-weight-bold">Order Details</v-card-title>
          <v-card-subtitle class="px-6 black--text">Customer: <span class="font-weight-bold">{{ order.customer }}</span><div class="text-end mt-n5 d-none d-print-block">Dealer: <span class="font-weight-bold">{{ order.dealerName }}</span></div></v-card-subtitle>
          <v-card-text>
            <v-list class="">
              <v-list-item class="py-0 mt-n9 mb-n6">
                <v-list-item-content class="">
                  <span class="text-h6 font-weight-bold">{{order.modelNameOverride ? order.modelNameOverride : order.modelName }}  {{ order.seriesName }}</span>  
                </v-list-item-content>
                <v-list-item-action-text>
                  <div class="font-weight-bold black--text">${{ order.retailSeriesPrice }}</div>
                </v-list-item-action-text>
              </v-list-item>
              
              <v-list-item
                  v-for="option in sortColorOptions"
                  :key="option.optionTypeId"
                  class="py-0 my-n4">
                <v-list-item-content>
                  <div> {{ option.optionTypeName }}: <span class="subtitle-1 font-weight-bold">{{ option.optionName }}</span></div>
                </v-list-item-content>
                <v-list-item-action-text>
                  <div v-if='option.retailPrice!=0' class="font-weight-bold black--text"> ${{option.retailPrice}}</div>
                  <div v-else class="font-weight-medium font-italic black--text">Included</div>
                </v-list-item-action-text>
              </v-list-item>
                         
              <v-list-item
                  v-for="option in sortOtherOptions"
                  :key="option.optionTypeId"
                  class="py-0 my-n4">
                <v-list-item-content>
                  <div> {{ option.optionTypeName }}: <span class="subtitle-1 font-weight-bold">{{ option.optionName }}</span></div>
                </v-list-item-content> 
                 <v-list-item-action-text>
                  <div v-if='option.retailPrice!=0' class="font-weight-bold black--text"> ${{option.retailPrice}}</div>
                  <div v-else class="font-weight-medium font-italic black--text">Included</div>
                </v-list-item-action-text>
              </v-list-item>
               
             
            <v-list-item class="py-0 mt-n4">
              <v-list-item-content>
                <div class="subtitle-1 font-weight-bold">Order Total</div>
              </v-list-item-content>
              <v-list-item-action-text>
            <div class="font-weight-bold black--text">${{order.retailCost}}</div>
            </v-list-item-action-text>
            </v-list-item>
            
            <v-list-item v-if="order.specialInstructions" class="py-0 my-n6">
              <v-list-item-content>
                <div> <span class="subtitle-1 font-weight-bold">{{"Dealer Special Instructions: "}}</span><span class="">{{order.specialInstructions}}</span></div>
              </v-list-item-content>
              
            </v-list-item>  
            
            </v-list>
          </v-card-text>
          <v-card-subtitle class="black--text mt-n6 d-none d-print-block">
            <div class="text-left">Order Date: <span class="font-weight-bold">{{ moment.utc(order.createdAt).local().format('MM/DD/YYYY') }}</span></div><div class="text-center mt-n5">Order Placed By: <span class="font-weight-bold">{{ order.usersFirstName + " " + order.usersLastName }}</span></div><div class="text-end mt-n5">Order Number: <span class="font-weight-bold">{{ this.orderId }}</span></div>
          </v-card-subtitle>
        <!-- </v-col>
      </v-row> -->
      
    </v-card>
  </v-sheet>
</template>

<script>
import { getOrder} from '@/services';

export default {
  name: "SalesOrderEndCust",
    
  data () {
    return {
      order:{},
      moment: require('moment'),
    };    
  },
  props: ['filter', 'orderId'],

  mounted() {

    getOrder(this.orderId).then((result) => {
      this.order = result.data;
    })

 
  },

  watch: {
    orderId: function () {
      getOrder(this.orderId).then((result) => {
        this.order = result.data;});
    }
  },


  computed: {
    userTypeId() { return this.$store.getters.userTypeId },
    sortColorOptions: function() {
      let sortedOptions = this.order.options.filter(o => o.group === 'color');
      sortedOptions.sort((a, b) => 
         a.optionTypeSequence - b.optionTypeSequence);
          return sortedOptions;},

    sortOtherOptions: function() {
      let sortedOptions = this.order.options.filter(o => (o.group === 'other' || o.group === 'add-ons'));
      sortedOptions.sort((a, b) => 
         a.optionTypeSequence - b.optionTypeSequence);
          return sortedOptions;},
   },
  methods: {
   
  },
};

</script>
